.logout-button {
  margin-top: 42px;
  padding: 1rem;

  width: 100%;

  color: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;

  background: #4c8bf5;
  border: 0;
  border-radius: 6px;
}