#login-screen {

  background: url(./Forestry.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background: #f9f8f7;
  display: flex;
  flex-direction: row-reverse;

  align-items: center;
  justify-content: center;

  font-family: 'Roboto';
  font-size: 18px;

  #login-form {
    display: flex;
    flex-direction: column;
    
    box-sizing: border-box;
    padding: 2rem;
    width: 60vw;
    max-width: 800px;
    background: white;
    box-shadow: rgb(59 59 59 / 5%) 0px 5px 15px 0px;
    border-radius: 10px;
  }

  #login-form>input {
    background: #ffffff;
    padding: 20px;
    font-size: 1.2em;
    border-radius: 20px;
    border: 1px solid #eee;
    margin-top: 20px;
  }

  input[type='checkbox'] {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 10px;
    vertical-align: middle;
  }

  #login-remember {
    margin-top: 10px;
    flex-direction: row;

    display: none !important;
  }

  #login-remember>span {
    margin-left: 17px;
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    vertical-align: middle;
  }

  #login-form-submit {

    margin-top: 42px;
    min-width: 150px;
    width: min-content;
    height: 48px;
    align-self: center;

    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: white;

    background: #4c8bf5;
    border: 0;
    border-radius: 20px;

    transition: background 0.2s;
  }

  #login-form-submit:hover {
    cursor: pointer;
    background: #2d71e7;
  }

  .third-party-login,
  .reset-password {
    font-style: normal;
    font-weight: normal;
    // font-size: 18px;
    line-height: 21px;
    text-align: right;
    text-decoration: none;

    color: #333;

    padding: 1rem;
    width: max-content;
  }

  .third-party-login:hover,
  .reset-password:hover {
    color: #111;
  }


  #login-form-logo {
    width: 200px;
    margin: auto;
  }

  .header, .tagline {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    
    line-height: 28px;
    margin-top: 1rem;
    text-align: center;
  }

  .header {
    font-family: 'Russo One';
    font-size: 2.5rem;
    color: #111;
  }

  .tagline {
    color: #111;
  }

}

@media (max-width: 320px) {

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  #login-form {
    right: 20px;
    top: 40px;
  }

  #login-form-logo {
    width: 200px;
    height: 102px;
  }

  #login-remember>span,
  .tagline {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 480px) {

  /* smartphones, Android phones, landscape iPhone */
  #login-form {
    right: 20px;
    top: 40px;
  }

  #login-form-logo {
    width: 200px;
    height: 102px;
  }

  #login-remember>span,
  .tagline {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}