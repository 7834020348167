.sidebar {
  $vertical-padding: 1rem;

  &-component {
    display: flex;
    padding: 2rem $vertical-padding 0rem $vertical-padding;

    min-width: 15vw;
    min-height: 100vh; 

    color: white;
    font-size: 0.875rem;
    background-color: #282c34;

    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    position: relative;
  }

  &-logo {
    height: 6rem;
    padding-bottom: 0.2rem;
  }

  &-section {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &_p1 {
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    text-align: center;

    margin-left: -$vertical-padding;
    padding: 1rem;
    width: 100%;
    display: flex;
  }

  &_p1:hover, &_p1.selected {
    background-color: #4c8bf5;
  }

  &__p6 {
    border-top: 3px grey solid;
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    text-align: center;
    margin: 0;
    padding: 1rem;
  }

  &__p6:hover {
    background-color: #4c8bf5;
  }

  &-wrapper {
    align-items: center;
    padding: 2rem;
  }

  &-wrapper:hover {
    background-color: #4c8bf5;
  }

  &-img{
    filter: invert(1);
    height: 1em;
    padding-right: 1rem; 
  }

  &_category {
    $fontSize: 1.5rem;
    font-weight: bold;
    font-size: $fontSize;
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;

    h1 {
      font-size: $fontSize;
    }
  }
}