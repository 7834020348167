.clientLoadingFallback {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-animationContainer {
    display: flex;
    width: 100px;
    height: 60px;
    align-items: center;
    justify-content: space-between;

    &-growingBlock {
      width: 5px;
      height: 40px;
      background-color: #4c8bf5;
      animation: grow 2s infinite;
    }
  }
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }

  50% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(0);
  }
}